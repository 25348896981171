import { useMemo } from 'react';

import { useMostRelatedCategoriesContext } from '../../context/MostRelatedCategoriesContext/MostRelatedCategoriesContext';
import { buildCategoryUrl, buildMicroCategoryUrl, buildWebsiteTemplatesUrl } from '../../utils/urlBuilders';
import { WithTranslation } from '@wix/wix-i18n-config';
import { useConfigContext } from '../../context/ConfigContext/ConfigContext';
import { useTemplateContext } from '../../context/TemplateContext/TemplateContext';

export interface IUseSubHeaderParams {
  t: WithTranslation['t'];
}

export const useSubHeader = ({ t }: IUseSubHeaderParams) => {
  const { categories, intentCategory } = useMostRelatedCategoriesContext();
  const { lang = 'en' } = useConfigContext();
  const websiteTemplatesUrl = buildWebsiteTemplatesUrl(lang);
  const { template } = useTemplateContext();

  // TODO: refactor with seo breadcrumbs?
  const breadcrumbs = useMemo(() => {
    return [
      {
        url: websiteTemplatesUrl,
        name: t('seo.breadcrumbs.websiteTemplates'),
      },
      ...(categories?.length
        ? categories?.map((category, index) => ({
            url: buildCategoryUrl(
              websiteTemplatesUrl,
              categories?.slice(0, index + 1).map(({ slug }) => slug),
            ),
            name: category.displayName,
          }))
        : []),
      ...(intentCategory
        ? [
            {
              url: buildMicroCategoryUrl(websiteTemplatesUrl, intentCategory.slug),
              name: intentCategory.displayName,
            },
          ]
        : []),
    ];
  }, [categories, intentCategory, t, websiteTemplatesUrl]);

  return { breadcrumbs, templateName: template.title, url: template.url, editorUrl: template.editorUrl };
};
