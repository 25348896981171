import React, { Dispatch, SetStateAction } from 'react';

interface TemplateTitleContextShape {
  setIsTitleVisible: Dispatch<SetStateAction<boolean>>;
  isTitleVisible: boolean;
}

const defaultTemplateTitleContextValue: TemplateTitleContextShape = {
  setIsTitleVisible: () => void undefined,
  isTitleVisible: false,
};

const TemplateTitleContext = React.createContext(defaultTemplateTitleContextValue);

const useTemplateTitleContext = () => React.useContext(TemplateTitleContext);

export { useTemplateTitleContext, TemplateTitleContext, TemplateTitleContextShape };
