import React, { FC } from 'react';

import { ChevronLeftLarge } from '@wix/wix-ui-icons-common';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';

import { WixLogo } from '../partials/WixLogo/WixLogo';
import { HeaderNavigation } from './Navigation/Navigation';
import { useSaveTemplateButtonContext } from '../../context/SaveTemplateButtonContext/SaveTemplateButtonContext';
import { SaveTemplateButton } from '../SaveTemplateButton/SaveTemplateButton';
import { useTemplateContext } from '../../context/TemplateContext/TemplateContext';
import { useTemplateTitleContext } from '../../context/TemplateTitleContext/TemplateTitleContext';
import { templatePageLog } from '../../utils/bi';
import s from './Header.scss';

interface IHeaderProps extends WithTranslation {
  dataHook?: string;
}

export const HeaderCmp: FC<IHeaderProps> = ({ dataHook, t }) => {
  const { template } = useTemplateContext();
  const { isButtonVisible } = useSaveTemplateButtonContext();
  const { isTitleVisible } = useTemplateTitleContext();
  const isMobileViewPort = globalThis.innerWidth < 834;
  const isShowBackButton =
    template.backToTemplatesUrl || template.origin !== undefined || template.prevPreview === 'preview';

  const onBackToTemplatesClick = () => {
    templatePageLog({
      templateId: template.templateId,
      location: 'header',
      buttonName: 'back',
    });

    if (template.origin === 'intro' || template.prevPreview === 'preview') {
      window.history.back();
    } else if (template.backToTemplatesUrl !== undefined) {
      window.location.assign(template.backToTemplatesUrl);
    }
  };

  return (
    <div data-hook={dataHook} className={s.header}>
      <div className={s.logo}>
        {isShowBackButton && (
          <button className={s.back} onClick={onBackToTemplatesClick}>
            <ChevronLeftLarge />
          </button>
        )}
        <WixLogo href="https://www.wix.com" />
        {!isTitleVisible && (
          <h1 className={s.title}> {t('template.viewer.v2.demo.template.name', { name: template.title })}</h1>
        )}
      </div>
      {!isButtonVisible && isMobileViewPort && <SaveTemplateButton />}
      <div className={s.navigation}>
        <HeaderNavigation />
      </div>
    </div>
  );
};

export const Header = withTranslation()(HeaderCmp);
