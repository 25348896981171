import React, { FC, useState } from 'react';
import { SkeletonGroup, SkeletonRectangle } from '@wix/design-system';

import { useIframeBiTimeTracking } from '../../../../utils/useIframeBiTimeTracking';

import s from './DesktopView.scss';

interface Props {
  url: string;
  title: string;
  templateId: string;
}

export const DesktopView: FC<Props> = ({ url, title, templateId }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  useIframeBiTimeTracking({ isLoaded, templateId });

  return (
    <div className={s.display}>
      {isLoaded ? null : (
        <SkeletonGroup className={s.skeletonGroup}>
          <SkeletonRectangle className={s.skeleton} />
        </SkeletonGroup>
      )}
      <iframe src={url} title={title} className={s.iframe} onLoad={() => setIsLoaded(true)} />
    </div>
  );
};
