import { RefObject, useEffect } from 'react';

export const useIsVisible = <T extends Element>({
  ref,
  cb,
  options,
}: {
  ref?: RefObject<T>;
  cb: (arg: boolean) => void;
  options?: IntersectionObserverInit;
}) => {
  useEffect(() => {
    const observer = new globalThis.IntersectionObserver(([entry]) => {
      cb(entry.isIntersecting);
    }, options);
    if (ref?.current) {
      observer.observe(ref?.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [cb, options, ref]);
};
