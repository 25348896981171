import React from 'react';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';
import { Button } from '@wix/design-system';

import { reportEditorClick, templatePageLog } from '../../../utils/bi';
import { useTemplateContext } from '../../../context/TemplateContext/TemplateContext';

import s from './Navigation.scss';

interface IHeaderNavigationProps extends WithTranslation {}

export const HeaderNavigationCmp = ({ t }: IHeaderNavigationProps) => {
  const onEditorClick = () => {
    reportEditorClick({ origin: 'viewer_top' });
  };
  const { template } = useTemplateContext();

  const onClick = () => {
    templatePageLog({
      templateId: template.templateId,
      location: 'header',
      buttonName: 'view_demo_site',
    });
  };

  return (
    <div className={s.navigation}>
      <Button
        priority="secondary"
        href={template.url}
        className={s.secondary}
        as="a"
        size="large"
        target="_blank"
        onClick={onClick}
      >
        {t('template.viewer.v2.demo.button')}
      </Button>
      <Button target="_blank" href={template.editorUrl} as="a" size="large" onClick={onEditorClick}>
        {t('template.viewer.v2.edit.button')}
      </Button>
    </div>
  );
};

export const HeaderNavigation = withTranslation()(HeaderNavigationCmp);
