import React, { FC } from 'react';
import cs from 'classnames';
import { VIEW_MODE } from '../../utils/helpers';
import { useTemplateContext } from '../../context/TemplateContext/TemplateContext';
import s from './WixStudioTemplateDemo.scss';
import { CopyPasteHint } from '../CopyPasteHint';

interface Props {
  dataHook?: string;
  viewMode: string;
}

export const WixStudioTemplateDemo: FC<Props> = ({ viewMode, dataHook }) => {
  const { template } = useTemplateContext();
  const isMobile = viewMode === VIEW_MODE.MOBILE;
  const url = isMobile ? `${template.url}?showMobileView=true` : template.url;

  return (
    <div data-hook={dataHook} data-view-mode={viewMode} className={s.container}>
      <iframe
        title={template.title}
        src={url}
        className={cs(s.iframe, {
          [s.tablet]: viewMode === VIEW_MODE.TABLET,
          [s.mobile]: viewMode === VIEW_MODE.MOBILE,
        })}
      />

      <CopyPasteHint />
    </div>
  );
};
