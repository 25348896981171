import React, { useContext } from 'react';

interface UrlParamsContextShape {
  petri_ovr?: string;
  siteCreationFolderId?: string;
  dashboardFirstMetaSiteId?: string;
}

export const defaultContextValue: UrlParamsContextShape = {
  petri_ovr: '',
  siteCreationFolderId: '',
  dashboardFirstMetaSiteId: '',
};

const UrlParamsContext = React.createContext<UrlParamsContextShape>(defaultContextValue);
UrlParamsContext.displayName = 'UrlParamsContext';

const useUrlParamsContext = () => useContext(UrlParamsContext);

export { UrlParamsContext, UrlParamsContextShape, useUrlParamsContext };
