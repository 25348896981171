import React, { FC, useMemo } from 'react';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';

import { Accordion } from '../Accordion/Accordion';
import { useTemplateContext } from '../../context/TemplateContext/TemplateContext';
import { APPS_DETAILS } from '../../utils/apps';
import { templatePageLog } from '../../utils/bi';
import s from './TemplateDescription.scss';

interface ITemplateDescriptionProps extends WithTranslation {}

const TemplateDescriptionCmp: FC<ITemplateDescriptionProps> = ({ t }) => {
  const { template } = useTemplateContext();
  const { goodFor, description } = template;

  const apps = useMemo(() => {
    if (!template?.apps?.length) {
      return [];
    }
    return APPS_DETAILS.filter(({ id }) => template?.apps?.find(({ appDefId }) => appDefId === id));
  }, [template.apps]);

  const onClick = (name: string) => () => {
    templatePageLog({
      templateId: template.templateId,
      location: 'product_page',
      buttonName: name,
    });
  };

  return (
    <div className={s.container}>
      <div className={s.infoContainer}>
        <div className={s.info}>
          <span className={s.title}>{t('template.viewer.v2.template.info')}</span>
          <p className={s.description}>{goodFor}</p>
        </div>
        <div className={s.info}>
          <span className={s.title}>{t('template.viewer.v2.template.details')}</span>
          <p className={s.description}>{description}</p>
        </div>
      </div>
      {Boolean(apps.length) && (
        <div className={s.appsContainer}>
          <div className={s.infoApps}>
            <span className={s.title}>{t('template.viewer.v2.template.apps')}</span>
            <p className={s.description}>{t('template.viewer.v2.template.apps.description')}</p>
          </div>
          <div className={s.apps}>
            {apps.map(({ id, translationKey, translationKeyDescription }) => (
              <Accordion onClick={onClick(t(translationKey))} key={id} title={t(translationKey)}>
                {t(translationKeyDescription)}
              </Accordion>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export const TemplateDescription = withTranslation()(TemplateDescriptionCmp);
