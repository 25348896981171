import * as ids from '@wix/app-definition-ids';

import messages from '../../assets/locale/marketing-template-viewer/messages_en.json';

type AppId = Extract<(typeof ids)[keyof typeof ids], string>;

export type AppDetails = {
  id: AppId;
  biType: string;
  translationKey: keyof typeof messages;
  translationKeyDescription: keyof typeof messages;
  specs?: Record<string, string>;
};

// order is important
export const APPS_DETAILS = [
  {
    id: ids.WIX_BOOKINGS,
    biType: 'Bookings',
    translationKey: 'APP_BOOKINGS',
    translationKeyDescription: 'APP_BOOKINGS_DESCRIPTION',
  },
  {
    id: ids.WIX_NEW_STORES,
    biType: 'Online Stores',
    translationKey: 'APP_STORES',
    translationKeyDescription: 'APP_STORES_DESCRIPTION',
  },
  {
    id: ids.WIX_EVENTS,
    biType: 'Events',
    translationKey: 'APP_EVENTS',
    translationKeyDescription: 'APP_EVENTS_DESCRIPTION',
  },
  {
    id: ids.WIX_PORTFOLIO,
    biType: 'Portfolio',
    translationKey: 'APP_PORTFOLIO',
    translationKeyDescription: 'APP_PORTFOLIO_DESCRIPTION',
  },
  {
    id: ids.PRICING_PLANS,
    biType: 'Pricing Plans',
    translationKey: 'APP_PLANS',
    translationKeyDescription: 'APP_PLANS_DESCRIPTION',
  },
  {
    id: ids.WIX_CHALLENGES,
    biType: 'Online Programs',
    translationKey: 'APP_PROGRAMS',
    translationKeyDescription: 'APP_PROGRAMS_DESCRIPTION',
  },
  {
    id: ids.WIX_RESTAURANTS_MENUS_NEW,
    biType: 'Restaurant Menus',
    translationKey: 'APP_MENUS',
    translationKeyDescription: 'APP_MENUS_DESCRIPTION',
  },
  {
    id: ids.WIX_BLOG,
    biType: 'Blog',
    translationKey: 'APP_BLOG',
    translationKeyDescription: 'APP_BLOG_DESCRIPTION',
  },
  {
    id: ids.WIX_RESTAURANTS_ORDERS_NEW,
    biType: 'Food Orders',
    translationKey: 'APP_ORDERS',
    translationKeyDescription: 'APP_ORDERS_DESCRIPTION',
  },
  {
    id: ids.WIX_RESTAURANTS_TABLE_RESERVATIONS,
    biType: 'Table Reservations',
    translationKey: 'APP_RESERVATIONS',
    translationKeyDescription: 'APP_RESERVATIONS_DESCRIPTION',
  },
  {
    id: ids.WIX_DONATIONS,
    biType: 'Wix Donations',
    translationKey: 'APP_DONATIONS',
    translationKeyDescription: 'APP_DONATIONS_DESCRIPTION',
  },
  {
    id: ids.WIX_HOTELS_RUNNER,
    biType: 'Wix Hotels',
    translationKey: 'APP_HOTELS',
    translationKeyDescription: 'APP_HOTELS_DESCRIPTION',
    specs: { 'specs.funnel.EnableHotelsAppInChat': 'true' },
  },
  {
    id: ids.WIX_GROUPS,
    biType: 'Groups',
    translationKey: 'APP_GROUPS',
    translationKeyDescription: 'APP_GROUPS_DESCRIPTION',
  },
  {
    id: ids.INSTAGRAM_FEED_NEW,
    biType: 'Instagram',
    translationKey: 'APP_INSTAGRAM',
    translationKeyDescription: 'APP_INSTAGRAM_DESCRIPTION',
  },
  {
    id: ids.WIX_VIDEO,
    biType: 'Video',
    translationKey: 'APP_VIDEO',
    translationKeyDescription: 'APP_VIDEO_DESCRIPTION',
  },
  {
    id: ids.WIX_MUSIC,
    biType: 'Music',
    translationKey: 'APP_MUSIC',
    translationKeyDescription: 'APP_MUSIC_DESCRIPTION',
  },
  {
    id: ids.WIX_FILE_SHARE,
    biType: 'File Share',
    translationKey: 'APP_FILE_SHARE',
    translationKeyDescription: 'APP_FILE_SHARE_DESCRIPTION',
  },
  {
    id: ids.WIX_FORMS,
    biType: 'Forms',
    translationKey: 'APP_FORMS',
    translationKeyDescription: 'APP_FORMS_DESCRIPTION',
  },
  {
    id: ids.WIX_CHAT,
    biType: 'Chat',
    translationKey: 'APP_CHAT',
    translationKeyDescription: 'APP_CHAT_DESCRIPTION',
  },
] as const;
