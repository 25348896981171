import React, { Dispatch, SetStateAction } from 'react';

interface SaveTemplateButtonContextShape {
  setIsButtonVisible: Dispatch<SetStateAction<boolean>>;
  setIsEmailSent: Dispatch<SetStateAction<boolean>>;
  setEmailError: Dispatch<SetStateAction<string | null>>;
  saveTemplate: VoidFunction;
  sendEmail: VoidFunction;
  onClose: VoidFunction;
  isButtonVisible: boolean;
  isSaveScreenOpen: boolean;
  isEmailSent: boolean;
  emailError: string | null;
}

const defaultSaveTemplateButtonContextValue: SaveTemplateButtonContextShape = {
  setEmailError: () => void undefined,
  setIsEmailSent: () => void undefined,
  setIsButtonVisible: () => void undefined,
  sendEmail: () => void undefined,
  onClose: () => void undefined,
  isButtonVisible: false,
  isSaveScreenOpen: false,
  isEmailSent: false,
  emailError: '',
  saveTemplate: () => void undefined,
};

const SaveTemplateButtonContext = React.createContext(defaultSaveTemplateButtonContextValue);

const useSaveTemplateButtonContext = () => React.useContext(SaveTemplateButtonContext);

export { useSaveTemplateButtonContext, SaveTemplateButtonContext, SaveTemplateButtonContextShape };
