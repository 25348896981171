import React, { FC, useCallback, useEffect, useRef } from 'react';
import cs from 'classnames';
import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';

import { VIEW_MODE } from '../../../utils/helpers';
import { DesktopView } from '../../partials/DesktopView/v2/DesktopView';
import { MobileView } from '../../partials/MobileView/v2/MobileView';
import { useTemplateContext } from '../../../context/TemplateContext/TemplateContext';
import { useUIContext } from '../../../context/UIContext/UIContext';
import s from './TemplateDemo.scss';

interface ITemplateDemo extends WithTranslation {
  dataHook?: string;
}

const TemplateDemoCmp: FC<ITemplateDemo> = ({ t }) => {
  const { viewMode, isInfoShown, toggleViewMode } = useUIContext();
  const { template } = useTemplateContext();
  const { url, title, templateId } = template;
  const refFlag = useRef<boolean>(false);
  const isDesktop = viewMode === VIEW_MODE.DESKTOP;
  const isMobile = viewMode === VIEW_MODE.MOBILE;

  const onResize = useCallback(() => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    if (vw < 834 && isDesktop) {
      toggleViewMode(VIEW_MODE.MOBILE);
    }
  }, [isDesktop, toggleViewMode]);

  useEffect(() => {
    if (!refFlag.current) {
      refFlag.current = true;
      onResize();
    }
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [isDesktop, isMobile, onResize, refFlag, toggleViewMode]);

  return (
    <div>
      <div className={s.deviceToggle}>
        <div className={s.buttons}>
          <button
            className={cs(s.button, s.test, { [s.selected]: isDesktop })}
            onClick={() => toggleViewMode(VIEW_MODE.DESKTOP)}
          >
            <div className={cs(s.desktopIcon, s.icon)} />
            <span className={s.hide}>{t('a11y.desktop.button')}</span>
          </button>
          <hr className={s.divider} />
          <button className={cs(s.button, { [s.selected]: isMobile })} onClick={() => toggleViewMode(VIEW_MODE.MOBILE)}>
            <div className={cs(s.mobileIcon, s.icon)} />
            <span className={s.hide}>{t('a11y.mobile.button')}</span>
          </button>
        </div>
      </div>
      <div className={cs(s.demoDisplay, { [s.disabled]: isInfoShown, [s.shadow]: isDesktop })}>
        <div className={s.desktop}>
          {isDesktop ? (
            <DesktopView templateId={templateId} url={url} title={title} />
          ) : (
            <MobileView templateId={templateId} url={url} title={title} />
          )}
        </div>
      </div>
    </div>
  );
};

export const TemplateDemo = withTranslation()(TemplateDemoCmp);
