import React, { FC, useState } from 'react';
import { TemplateTitleContext } from './TemplateTitleContext';

export const TemplateTitleContextProvider: FC = ({ children }) => {
  const [isTitleVisible, setIsTitleVisible] = useState<boolean>(true);

  return (
    <TemplateTitleContext.Provider
      value={{
        isTitleVisible,
        setIsTitleVisible,
      }}
    >
      {children}
    </TemplateTitleContext.Provider>
  );
};
