import React, { FC, useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';
import * as BIManager from '../../utils/bi';
import { useExperimentContext } from '../../context/ExperimentContext/ExperimentContext';
import { useTemplateContext } from '../../context/TemplateContext/TemplateContext';
import HintIcon from '../../../icons/Hint.svg';
import CloseIcon from '../../../icons/closeCross.svg';
import s from './CopyPasteHint.scss';

interface Props extends WithTranslation {
  dataHook?: string;
}

const LS_IS_HIDE_HINT_KEY = 'marketing-templates-viewer-copy-paste-hint';
const LEARN_MORE_URL = 'https://support.wix.com/en/article/studio-editor-saving-and-reusing-design-assets';

export const CopyPasteHintCmp: FC<Props> = ({ t }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { activeExperiments } = useExperimentContext();
  const { template } = useTemplateContext();

  useEffect(() => {
    if (window.localStorage.getItem(LS_IS_HIDE_HINT_KEY)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    if (!isOpen) {
      window.localStorage.setItem(LS_IS_HIDE_HINT_KEY, 'hide');
      BIManager.reportStudioTemplateGeneralAction({ template, action: 'close', actionValue: 'copy_section' });
    }
  }, [isOpen]);

  const onLearnMoreClick = () => {
    BIManager.reportStudioTemplateGeneralAction({ template, action: 'learn_more', actionValue: 'copy_section' });
  };

  const isShowHint = isOpen && activeExperiments.includes('CopyPasteHintStudioTemplates');

  if (!isShowHint) {
    return null;
  }

  return (
    <div className={s.copyPasteTip}>
      <div className={s.copyPasteText}>
        <HintIcon style={{ minWidth: '30px' }} />

        <div>
          <strong>{t('template.viewer.hint.copyPaste.title')}</strong> {t('template.viewer.hint.copyPaste.text')}{' '}
          <a
            onClick={onLearnMoreClick}
            className={s.copyPasteLink}
            target="_blank"
            rel="noreferrer"
            href={LEARN_MORE_URL}
          >
            {t('template.viewer.hint.copyPaste.urlTitle')}
          </a>
        </div>
      </div>

      <CloseIcon style={{ minWidth: '30px' }} onClick={() => setIsOpen(false)} className={s.copyPasteClose} />
    </div>
  );
};

export const CopyPasteHint = withTranslation()(CopyPasteHintCmp);
