import { useRef } from 'react';

import { iframeFinishedLoading, iframeStartLoading } from './bi';

export const useIframeBiTimeTracking = ({ templateId, isLoaded }: { templateId: string; isLoaded: boolean }) => {
  const timeStartRef = useRef<number>(0);

  if (isLoaded && timeStartRef.current) {
    const loadingTime = Date.now() - timeStartRef.current;
    iframeFinishedLoading({
      template_id: templateId,
      loading_time: Number.isNaN(loadingTime) ? 0 : loadingTime,
    });
    timeStartRef.current = 0;
  } else if (!timeStartRef.current && !isLoaded) {
    iframeStartLoading(templateId);
    timeStartRef.current = Date.now();
  }
};
