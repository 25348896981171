export const buildHomePageUrl = (lang: string) => {
  const langDomain = `${lang === 'en' ? 'www' : lang}.wix.com`;
  return `https://${langDomain}`;
};

export const buildViewerUrl = (lang: string, templateId: string) =>
  `${buildHomePageUrl(lang)}/website-template/view/html/${templateId}`;

export const buildStudioTemplatesUrl = (lang: string) => `${buildHomePageUrl(lang)}/studio/templates`;

export const buildWebsiteTemplatesUrl = (lang: string) => `${buildHomePageUrl(lang)}/website/templates`;

const STUDIO_CATEGORY_SLUG_PREFIX = 'studio-';
const resolveCategorySlug = (slug: string) => {
  if (slug.startsWith(STUDIO_CATEGORY_SLUG_PREFIX)) {
    return slug.slice(STUDIO_CATEGORY_SLUG_PREFIX.length);
  }

  return slug;
};

export const buildCategoryUrl = (baseUrl: string, categorySlugs: string[]) =>
  `${baseUrl}/html/${categorySlugs.map(resolveCategorySlug).join('/')}`;

export const buildMicroCategoryUrl = (baseUrl: string, microCategorySlug: string) =>
  `${baseUrl}/refine/${resolveCategorySlug(microCategorySlug)}`;
