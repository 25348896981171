function keyIndex(order: string[], key: string): number {
  const index = order.indexOf(key);
  return index === -1 ? order.length : index;
}

function stringify(params: { [key: string]: undefined | null | string }, options?: { keysOrder?: string[] }): string {
  const keysOrder = options?.keysOrder || [];
  const searchParams = new URLSearchParams();

  Object.entries(params)
    .filter(([, value]) => value !== null && value !== undefined)
    .sort(([keyOne], [keyTwo]) => keyIndex(keysOrder, keyOne) - keyIndex(keysOrder, keyTwo))
    .forEach(([key, value]) => searchParams.append(key, value ?? ''));

  return searchParams.toString();
}

export function getEditorLink({
  metaSiteId,
  editorSessionId,
  petri_ovr,
  dashboardFirstMetaSiteId,
  siteCreationFolderId,
}: {
  metaSiteId: string;
  editorSessionId: string;
  petri_ovr?: string;
  dashboardFirstMetaSiteId?: string;
  siteCreationFolderId?: string;
}): string {
  return `https://manage.wix.com/edit-template/from-intro${dashboardFirstMetaSiteId ? '-siteless' : ''}?${stringify({
    originTemplateId: metaSiteId,
    editorSessionId,
    petri_ovr,
    siteCreationFolderId,
    metaSiteId: dashboardFirstMetaSiteId,
  })}`;
}

export function getViewerLink({
  templateSlug,
  language,
  originUrl,
  clickedElement = 'view_button',
  editorSessionId,
  dashboardFirstMetaSiteId,
  prevPreview,
}: {
  templateSlug: string;
  language: string;
  originUrl: string;
  clickedElement?: 'view_button' | 'thumbnail';
  editorSessionId: string;
  dashboardFirstMetaSiteId?: string;
  prevPreview?: string;
}): string {
  return `https://${
    language === 'en' ? 'www' : language
  }.wix.com/website-template/view/html/${templateSlug}?${stringify({
    originUrl,
    tpClick: clickedElement,
    esi: editorSessionId,
    ...(prevPreview ? { prevPreview } : {}),
    ...(dashboardFirstMetaSiteId ? { dashboardFirstMetaSiteId } : {}),
  })}`;
}
