import React, { FC } from 'react';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';

import { useTemplateContext } from '../../context/TemplateContext/TemplateContext';
import { Thumbnail } from './Thumbnail/Thumbnail';

import s from './TemplateLikeThis.scss';

interface ITemplateLikeThisProps extends WithTranslation {}

const TemplateLikeThisCmp: FC<ITemplateLikeThisProps> = ({ t }) => {
  const { templatesLikeThis } = useTemplateContext();

  if (!templatesLikeThis?.length) {
    return null;
  }

  return (
    <div className={s.container}>
      <span className={s.title}>{t('template.viewer.v2.template.likeThis.title')}</span>
      <ul className={s.templates}>
        {templatesLikeThis.map((template) => (
          <Thumbnail key={template?.template?.templateId} templateData={template!} />
        ))}
      </ul>
    </div>
  );
};

export const TemplateLikeThis = withTranslation()(TemplateLikeThisCmp);
