import React, { useEffect } from 'react';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';
import { DismissFilled } from '@wix/wix-ui-icons-common';

import { useUserData } from '../../context/UserDataContext/UserDataContext';
import { useTemplateContext } from '../../context/TemplateContext/TemplateContext';
import { useSaveTemplateButtonContext } from '../../context/SaveTemplateButtonContext/SaveTemplateButtonContext';
import { ThankYouPageError } from './ThankYouPageError';
import { WixOwnerAppBanner } from './WixOwnerAppBanner/WixOwnerAppBanner';
import { Loader } from '../Loader/Loader';
import { logActionOnThankYouPage } from '../../utils/bi';
import s from './ThankYouDialog.scss';

export interface ThankYouProps extends WithTranslation {
  dataHook?: string;
}

const ThankYouDialogCmp: React.FC<ThankYouProps> = ({ t }) => {
  const userData = useUserData();
  const { template } = useTemplateContext();
  const { isSaveScreenOpen, onClose, emailError, sendEmail, isEmailSent } = useSaveTemplateButtonContext();

  useEffect(() => {
    const encodedUrl = encodeURIComponent(window.location.href);
    const signInUrl = `https://users.wix.com/signin?view=sign-up&loginCompName=SignUp_H&postSignUp=${encodedUrl}&postLogin=${encodedUrl}&forceRender=true`;

    if (!isSaveScreenOpen) {
      return;
    }

    if (!userData.isLoggedIn) {
      return window.location.assign(signInUrl);
    }

    sendEmail();
  }, [isSaveScreenOpen, sendEmail, userData.isLoggedIn]);

  if (!isSaveScreenOpen || !userData.isLoggedIn) {
    return null;
  }

  const onClick = () => {
    logActionOnThankYouPage('close');
    onClose();
  };

  if (emailError) {
    return (
      <div className={s.page}>
        <DismissFilled className={s.closeIcon} onClick={onClick} />
        <ThankYouPageError />
      </div>
    );
  }

  return (
    <div className={s.page}>
      <div className={s.content}>
        {isEmailSent ? (
          <>
            <div className={s.images}>
              <div className={s.desktopImageWrapper}>
                <div className={s.desktopImageFrame}>
                  <div className={s.desktopImageFrameDots}>
                    <div className={s.desktopImageFrameDot} />
                    <div className={s.desktopImageFrameDot} />
                    <div className={s.desktopImageFrameDot} />
                  </div>
                </div>
                <img
                  className={s.desktopImage}
                  src={template.desktopImage?.absoluteUrl}
                  alt={template.desktopImage?.alt}
                />
              </div>
              <div className={s.mobileImageWrapper}>
                <img
                  className={s.mobileImage}
                  src={template.mobileImage?.absoluteUrl}
                  alt={template.mobileImage?.alt}
                />
                <div className={s.mobileImageFooter} />
              </div>
            </div>
            <div className={s.title}>{t('thankYou.success.title')}</div>
            <div className={s.description}>{t('thankYou.success.description')}</div>
            <WixOwnerAppBanner />
          </>
        ) : (
          <div className={s.loaderWrapper}>
            <Loader />
          </div>
        )}
      </div>
      <DismissFilled className={s.closeIcon} onClick={onClick} />
    </div>
  );
};

export const ThankYouDialog = withTranslation()(ThankYouDialogCmp);
