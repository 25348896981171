import React, { FC, useCallback, useState, PropsWithChildren } from 'react';
import cs from 'classnames';
import { Confirm, Add, Minus } from '@wix/wix-ui-icons-common';

import s from './Accordion.scss';

interface IAccordionProps {
  onClick?: VoidFunction;
  title: string;
}

export const Accordion: FC<PropsWithChildren<IAccordionProps>> = ({ onClick, children, title }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    onClick?.();
    setIsOpen((state) => !state);
  }, [onClick]);

  return (
    <div className={s.container}>
      <button className={s.button} onClick={handleClick}>
        <Confirm className={s.leftIcon} />
        <span className={s.title}>{title}</span>
        <div className={s.rightIcon}>{isOpen ? <Minus /> : <Add />}</div>
      </button>
      <div className={cs(s.panel, { [s.open]: isOpen })}>{children}</div>
    </div>
  );
};
