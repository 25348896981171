import React, { FC, PropsWithChildren } from 'react';
import { MostRelatedCategoriesContextShape, MostRelatedCategoriesContext } from './MostRelatedCategoriesContext';

interface MostRelatedCategoriesContextProviderProps {
  initialState: MostRelatedCategoriesContextShape;
}

const VANILLA_ALL_CATEGORY_SLUG = 'all';
const STUDIO_ALL_CATEGORY_SLUG = 'studio-all-templates';
const isAllCategory = ({ slug }: { slug: string }) =>
  [VANILLA_ALL_CATEGORY_SLUG, STUDIO_ALL_CATEGORY_SLUG].includes(slug);

export const MostRelatedCategoriesContextProvider: FC<PropsWithChildren<MostRelatedCategoriesContextProviderProps>> = ({
  initialState,
  children,
}) => {
  const { categories } = initialState;

  const intentCategoryIdx = (categories || []).findIndex(({ type }) => type === 'INTENT');
  const intentCategory = categories?.[intentCategoryIdx];
  const relatedCategories = (intentCategory ? categories?.slice(0, intentCategoryIdx) : categories)?.filter(
    (category) => !isAllCategory(category),
  );

  return (
    <MostRelatedCategoriesContext.Provider
      value={{
        categories: relatedCategories,
        intentCategory,
      }}
    >
      {children}
    </MostRelatedCategoriesContext.Provider>
  );
};
