import React from 'react';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';
import ErrorUnavailableIcon from './ErrorUnavailable.svg';
import s from './ThankYouDialog.scss';

interface ThankYouPageErrorProps extends WithTranslation {
  dataHook?: string;
}

export const ThankYouPageErrorCmp: React.FC<ThankYouPageErrorProps> = ({ t }) => (
  <div className={s.content}>
    <div className={s.iconContainer}>
      <ErrorUnavailableIcon />
    </div>
    <div className={s.title}>{t('thankYou.error.title')}</div>
    <div className={s.description}>{t('thankYou.error.description')}</div>
  </div>
);

export const ThankYouPageError = withTranslation()(ThankYouPageErrorCmp);
