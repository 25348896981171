import { Asset, TemplateResult } from '@wix/ambassador-funnel-templates-gallery-v2-template/types';
import { useUrlParamsContext } from '../../../context/UrlParamsContext/UrlParamsContext';
import { getEditorLink, getViewerLink } from '../../../utils/templateLinks';
import { useConfigContext } from '../../../context/ConfigContext/ConfigContext';
import { uuidGenerator } from '../../../../server/utils/uuidGenerator';
import { logTemplateEdit, logTemplateView, templatePageLog } from '../../../utils/bi';
import { WithTranslation } from '@wix/wix-i18n-config';
import { useTemplateContext } from '../../../context/TemplateContext/TemplateContext';

export const useThumbnail = ({ templateData, t }: { templateData: TemplateResult; t: WithTranslation['t'] }) => {
  const { lang } = useConfigContext();
  const { template: currentTemplate } = useTemplateContext();
  const { template, metadata } = templateData;
  const { metaSiteId, title, assets, slug, siteId } = template!;
  const { score } = metadata!;

  const { petri_ovr, dashboardFirstMetaSiteId, siteCreationFolderId } = useUrlParamsContext();
  const editorSessionId = uuidGenerator();

  const onClick = (name: string) => {
    templatePageLog({
      templateId: currentTemplate.templateId,
      location: 'product_page',
      buttonName: name,
    });
  };

  const editorLink = getEditorLink({
    metaSiteId: metaSiteId ?? '',
    editorSessionId,
    petri_ovr,
    dashboardFirstMetaSiteId,
    siteCreationFolderId,
  });

  const viewerLink = getViewerLink({
    templateSlug: slug ?? '',
    language: lang ?? 'en',
    originUrl: globalThis?.location?.href,
    editorSessionId,
    dashboardFirstMetaSiteId,
    prevPreview: 'preview',
  });

  const editClick = () => {
    logTemplateEdit({
      score: score ?? 0,
      siteId: siteId ?? '',
      editorSessionId,
      metaSiteId: metaSiteId ?? '',
      openingBrowserUrl: editorLink,
    });
    onClick('edit');
  };
  const viewClick = () => {
    logTemplateView({
      score: score ?? 0,
      siteId: siteId ?? '',
      editorSessionId,
      openingBrowserUrl: viewerLink,
      buttonName: t('template.viewer.v2.template.button.view'),
    });
    onClick('view');
  };
  const { desktopImg, desktopImg16X9, mobileImg, video } = (assets || [])?.reduce(
    (acc, asset) => {
      const imgType = asset?.type ?? '';

      if (imgType === 'DESKTOP') {
        return { ...acc, desktopImg: asset };
      } else if (imgType === 'DESKTOP_16X9') {
        return { ...acc, desktopImg16X9: asset };
      } else if (imgType === 'MOBILE') {
        return { ...acc, mobileImg: asset };
      } else if (imgType === 'VIDEO') {
        return { ...acc, video: asset };
      }
      return acc;
    },
    {
      desktopImg: null,
      desktopImg16X9: null,
      mobileImg: null,
      video: null,
    } as { desktopImg: Asset | null; desktopImg16X9: Asset | null; mobileImg: Asset | null; video: Asset | null },
  );

  return {
    title,
    desktopImg,
    desktopImg16X9,
    mobileImg,
    hasVideo: Boolean(video),
    viewClick,
    editClick,
    editorLink,
    viewerLink,
  };
};
