import React from 'react';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';
import { Button } from '@wix/design-system';

import { useSubHeader } from './useSubHeader';
import { SaveTemplateButton } from '../SaveTemplateButton/SaveTemplateButton';
import { useSaveTemplateButtonContext } from '../../context/SaveTemplateButtonContext/SaveTemplateButtonContext';
import { useIsVisible } from '../../utils/useIsVisible';
import { useTemplateTitleContext } from '../../context/TemplateTitleContext/TemplateTitleContext';
import { templatePageLog } from '../../utils/bi';
import { useTemplateContext } from '../../context/TemplateContext/TemplateContext';
import s from './SubHeader.scss';

interface ISubHeaderProps extends WithTranslation {}

const SubHeaderCmp = ({ t }: ISubHeaderProps) => {
  const { breadcrumbs, templateName, url: demoUrl } = useSubHeader({ t });
  const { setIsButtonVisible } = useSaveTemplateButtonContext();
  const { isTitleVisible, setIsTitleVisible } = useTemplateTitleContext();
  const { template } = useTemplateContext();
  const saveButtonRef = React.createRef<HTMLDivElement>();
  const titleRef = React.createRef<HTMLDivElement>();
  const isMobileViewPort = globalThis.innerWidth < 834;
  const options = {
    root: null,
    rootMargin: '-72px 0px 0px 0px', // header height
    threshold: 0,
  };
  useIsVisible({
    ref: saveButtonRef,
    cb: setIsButtonVisible,
    options,
  });
  useIsVisible({
    ref: titleRef,
    cb: setIsTitleVisible,
    options,
  });

  const onClick = (name: string) => () => {
    templatePageLog({
      templateId: template.templateId,
      location: 'product_page',
      buttonName: name,
    });
  };

  return (
    <div className={s.subHeader}>
      <div className={s.titleContainer}>
        <div className={s.breadcrumbs}>
          {breadcrumbs.map(({ url, name }, i) => (
            <a
              onClick={onClick(name)}
              key={`${name}-${i}`}
              className={s.category}
              href={url}
              data-bi-element="breadcrumb"
              data-bi-element-value="breadcrumb"
              data-bi-gallery-doc-index="0"
            >
              {name}
            </a>
          ))}
        </div>
        <div ref={titleRef}>
          {!isTitleVisible && !isMobileViewPort ? (
            <div style={{ height: '42px' }} />
          ) : (
            <h1 className={s.title} data-hook="template-title">
              {t('template.viewer.v2.demo.template.name', { name: templateName })}
            </h1>
          )}
        </div>
      </div>
      <div className={s.navigation}>
        <div className={s.buttonContainer} ref={saveButtonRef}>
          <SaveTemplateButton className={s.button} />
        </div>
        <Button priority="secondary" href={demoUrl} as="a" size="large" target="_blank" className={s.secondary}>
          {t('template.viewer.v2.demo.button')}
        </Button>
      </div>
    </div>
  );
};

export const SubHeader = withTranslation()(SubHeaderCmp);
