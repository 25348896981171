import React, { useContext } from 'react';
import {
  Asset,
  Template as BaseTemplate,
  TemplateResult,
  TemplatePrice,
} from '@wix/ambassador-funnel-templates-gallery-v2-template/types';

export interface StoreTemplate extends BaseTemplate {
  url: string;
  description: string;
  title: string;
  docUrl: string;
  editorUrl: string;
  backToTemplatesUrl?: string;
  origin?: 'intro';
  prevPreview?: 'preview';
  goodFor: string;
  siteId: string;
  image: string;
  slug: string;
  templateId: string;
  lng: string;
  metaSiteId: string;
  editorSessionId: string;
  isResponsive: boolean;
  isStudio: boolean;
  isAdiTemplate?: boolean;
  mobileImage?: Asset;
  desktopImage?: Asset;
}

interface TemplateContextShape {
  template: StoreTemplate;
  templatesLikeThis?: TemplateResult[];
}

export const defaultTemplateContextValue: TemplateContextShape = {
  template: {
    url: '',
    templatePrice: TemplatePrice.UNDEFINED,
    description: '',
    title: '',
    docUrl: '',
    editorUrl: '',
    goodFor: '',
    siteId: '',
    image: '',
    slug: '',
    templateId: '',
    lng: '',
    metaSiteId: '',
    editorSessionId: '',
    isResponsive: false,
    isStudio: false,
    isAdiTemplate: false,
    apps: [],
  },
  templatesLikeThis: [],
};

const TemplateContext = React.createContext<TemplateContextShape>(defaultTemplateContextValue);
TemplateContext.displayName = 'TemplateContext';

const useTemplateContext = () => useContext(TemplateContext);

export { TemplateContext, TemplateContextShape, useTemplateContext };
