import React, { FC, PropsWithChildren } from 'react';
import { TemplateContextShape, TemplateContext } from './TemplateContext';

interface TemplateContextProviderProps {
  initialState: TemplateContextShape;
}

export const TemplateContextProvider: FC<PropsWithChildren<TemplateContextProviderProps>> = ({
  initialState,
  children,
}) => {
  const contextValue: TemplateContextShape = {
    template: initialState.template,
    templatesLikeThis: initialState.templatesLikeThis,
  };

  return <TemplateContext.Provider value={contextValue}>{children}</TemplateContext.Provider>;
};
