import React from 'react';
import { MobileApp } from '../components/MobileApp/MobileApp';
import { DesktopApp } from '../components/DesktopApp/DesktopApp';
import { AppV2 } from '../components/DesktopApp/v2/AppV2';
import { useExperimentContext } from '../context/ExperimentContext/ExperimentContext';
import { useTemplateContext } from '../context/TemplateContext/TemplateContext';
import './App.scss';

interface Props {
  mobile: boolean;
}

export const App = ({ mobile }: Props) => {
  const { activeExperiments } = useExperimentContext();
  const { template } = useTemplateContext();

  const isNewMarketingTemplateView = activeExperiments.includes('templateViewerV2');
  if (isNewMarketingTemplateView && !template.isStudio && !template.isResponsive) {
    return <AppV2 dataHook="app" />;
  }

  return mobile ? <MobileApp dataHook="app" /> : <DesktopApp dataHook="app" />;
};
