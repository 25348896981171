import React from 'react';
import WixOwnerAppIcon from './WixOwnerAppIcon.svg';
import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';
import { logInstallAppOnThankYouPage } from '../../../utils/bi';
import s from './WixOwnerAppBanner.scss';

interface WixOwnerAppBannerProps extends WithTranslation {
  dataHook?: string;
}

const WixOwnerAppBannerCmp: React.FC<WixOwnerAppBannerProps> = ({ t }) => {
  return (
    <div className={s.banner}>
      <WixOwnerAppIcon className={s.appIcon} />
      <div className={s.description}>{t('thankYou.wixOwnerAppBanner.description')}</div>
      <a href="https://vgal2.app.link/" className={s.cta} onClick={logInstallAppOnThankYouPage}>
        {t('thankYou.wixOwnerAppBanner.cta')}
      </a>
    </div>
  );
};

export const WixOwnerAppBanner = withTranslation()(WixOwnerAppBannerCmp);
