import React, { FC } from 'react';
import { Loader as BaseLoader } from '@wix/design-system';
import s from './Loader.scss';

interface LoaderProps {
  dataHook?: string;
}

export const Loader: FC<LoaderProps> = ({ dataHook }) => (
  <div className={s.root} data-hook={dataHook}>
    <BaseLoader size="large" />
  </div>
);
