import React, { FC, PropsWithChildren } from 'react';
import { UrlParamsContextShape, UrlParamsContext } from './UrlParamsContext';

interface UrlParamsContextProviderProps {
  urlParams: UrlParamsContextShape;
}

export const UrlParamsContextProvider: FC<PropsWithChildren<UrlParamsContextProviderProps>> = ({
  urlParams,
  children,
}) => {
  const contextValue: UrlParamsContextShape = {
    ...urlParams,
  };

  return <UrlParamsContext.Provider value={contextValue}>{children}</UrlParamsContext.Provider>;
};
