import React, { useEffect } from 'react';
import { TemplateDemo } from '../../TemplateDemo/v2/TemplateDemo';
import { Header } from '../../Header/Header';
import { SubHeader } from '../../SubHeader/SubHeader';
import { TemplateDescription } from '../../TemplateDescription/TemplateDescription';
import { TemplateLikeThis } from '../../TemplateLikeThis/TemplateLikeThis';
import { ThankYouDialog } from '../../ThankYouDialog/ThankYouDialog';
import * as BIManager from '../../../utils/bi';
import { fedopsLogger } from '../../../utils/fedops-logger';

import s from './AppV2.scss';

interface IAppV2Props {
  dataHook?: string;
}

export const AppV2 = ({ dataHook }: IAppV2Props) => {
  useEffect(() => {
    BIManager.reportLoadSuccess();
    fedopsLogger.appLoaded();
  }, []);

  return (
    <div className={s.main} data-hook={dataHook}>
      <Header />
      <div className={s.container}>
        <SubHeader />
        <TemplateDemo />
        <TemplateDescription />
      </div>
      <TemplateLikeThis />
      <ThankYouDialog />
    </div>
  );
};
