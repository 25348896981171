import React, { FC } from 'react';
import cs from 'classnames';
import { Button } from '@wix/design-system';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';
import { TemplateResult } from '@wix/ambassador-funnel-templates-gallery-v2-template/types';

import { useThumbnail } from './useThumbnail';

import s from './Thumbnail.scss';

interface IThumbnailProps extends WithTranslation {
  templateData: TemplateResult;
}

const ThumbnailCmp: FC<IThumbnailProps> = ({ templateData, t }) => {
  const { viewClick, editClick, title, desktopImg, desktopImg16X9, editorLink, viewerLink } = useThumbnail({
    templateData,
    t,
  });

  const alt = t('template.viewer.v2.demo.template.name', {
    name: title,
  });

  return (
    <li className={s.container}>
      <div className={s.thumbnail}>
        <a className={s.thumbnailLink} href={viewerLink} />
        <picture>
          <source media="(max-width: 1024px)" srcSet={desktopImg16X9?.absoluteUrl} />
          <img
            loading="lazy"
            className={s.img}
            alt={alt}
            src={desktopImg?.absoluteUrl ?? desktopImg16X9?.absoluteUrl}
          />
        </picture>
        <div className={s.hoverPanel} />
        <div className={s.navigation}>
          <Button className={s.button} as="a" size="large" onClick={editClick} href={editorLink}>
            {t('template.viewer.v2.template.button.edit')}
          </Button>
          <Button
            className={cs(s.secondary, s.button)}
            priority="secondary"
            as="a"
            size="large"
            onClick={viewClick}
            href={viewerLink}
          >
            {t('template.viewer.v2.template.button.view_details')}
          </Button>
        </div>
      </div>
      <span className={s.title}>{title}</span>
    </li>
  );
};

export const Thumbnail = withTranslation()(ThumbnailCmp);
