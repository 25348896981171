import React, { FC, useCallback, useEffect, useState } from 'react';
import { SaveTemplateButtonContext } from './SaveTemplateButtonContext';
import { HttpClient } from '@wix/http-client';
import { useTemplateContext } from '../TemplateContext/TemplateContext';

const SCREEN_TYPE = 'thank-you';

const httpClient = new HttpClient({ baseURL: 'https://www.wix.com/website/templates' });

export const SaveTemplateButtonContextProvider: FC = ({ children }) => {
  const { template } = useTemplateContext();

  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isSaveScreenOpen, setIsSaveScreenOpen] = useState(false);

  const [isEmailSent, setIsEmailSent] = useState(true);
  const [emailError, setEmailError] = useState<string | null>(null);

  const saveTemplate = useCallback(() => {
    setIsSaveScreenOpen(true);
    const urlParams = new URLSearchParams(globalThis?.location?.search);
    urlParams.set('screen', SCREEN_TYPE);
    globalThis?.history?.replaceState(null, '', `${globalThis?.location?.pathname}?${urlParams.toString()}`);
  }, []);

  const { editorSessionId, slug } = template;
  const searchParams = new URLSearchParams(globalThis?.location?.search);
  const param = searchParams.get('screen');

  const onClose = () => {
    setIsSaveScreenOpen(false);
    setEmailError(null);

    const urlParams = new URLSearchParams(globalThis?.location?.search);
    urlParams.delete('screen');
    globalThis?.history?.replaceState(null, '', `${globalThis?.location?.pathname}?${urlParams.toString()}`);
  };

  const sendEmail = useCallback(() => {
    setIsEmailSent(false);
    httpClient
      .post('api/template-mailer/send', {
        esid: editorSessionId,
        templateSlug: slug,
      })
      .then((data) => {
        if (data.status !== 204) {
          setEmailError(data.statusText);
        } else {
          emailError && setEmailError(null);
        }
      })
      .catch((err) => {
        setEmailError(err.message);
      })
      .finally(() => {
        setIsEmailSent(true);
      });
  }, [editorSessionId, slug, emailError]);

  useEffect(() => {
    if (param === SCREEN_TYPE && !isSaveScreenOpen) {
      setIsSaveScreenOpen(true);
    }
  }, [param, isSaveScreenOpen]);

  return (
    <SaveTemplateButtonContext.Provider
      value={{
        isButtonVisible,
        setIsButtonVisible,
        saveTemplate,
        isSaveScreenOpen,
        onClose,
        isEmailSent,
        emailError,
        sendEmail,
        setIsEmailSent,
        setEmailError,
      }}
    >
      {children}
    </SaveTemplateButtonContext.Provider>
  );
};
