import React from 'react';
import { Button } from '@wix/design-system';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';
import { useTemplateContext } from '../../context/TemplateContext/TemplateContext';
import { useMostRelatedCategoriesContext } from '../../context/MostRelatedCategoriesContext/MostRelatedCategoriesContext';
import { logSaveTemplateClick } from '../../utils/bi';
import { useSaveTemplateButtonContext } from '../../context/SaveTemplateButtonContext/SaveTemplateButtonContext';

interface SaveTemplateButtonProps extends WithTranslation {
  dataHook?: string;
  className?: string;
}

const SaveTemplateButtonCmp: React.FC<SaveTemplateButtonProps> = ({ t, className }) => {
  const {
    template: { metaSiteId, siteId, image, editorSessionId },
  } = useTemplateContext();
  const { categories } = useMostRelatedCategoriesContext();
  const { saveTemplate } = useSaveTemplateButtonContext();

  const [category, subcategory] = categories ?? [];

  const handleSaveButtonClick = async () => {
    logSaveTemplateClick({
      category: category?.categoryId ?? '',
      subcategory: subcategory?.categoryId ?? '',
      template_id: siteId,
      metasiteid: metaSiteId,
      esi: editorSessionId,
      imageUrl: image,
    });

    saveTemplate();
  };

  return (
    <Button className={className} onClick={handleSaveButtonClick}>
      {t('template.newMobileFlow.preview.saveTemplate')}
    </Button>
  );
};

export const SaveTemplateButton = withTranslation()(SaveTemplateButtonCmp);
