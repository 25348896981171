import React, { FC } from 'react';
import s from './WixLogo.scss';

interface Props {
  dataHook?: string;
  onClick?: VoidFunction;
  href?: string;
}

export const WixLogo: FC<Props> = ({ onClick, dataHook, href = '/' }) => (
  <a data-hook={dataHook} href={href} onClick={onClick} className={s.logo}>
    <span className={s.hide}>wix.com</span>
  </a>
);
