import React, { useContext } from 'react';
import { Category } from '../../../server/domain/category/Category';

interface MostRelatedCategoriesContextShape {
  categories?: Category[];
  intentCategory?: Category;
}

export const defaultMostRelatedCategoriesContextValue: MostRelatedCategoriesContextShape = {
  categories: undefined,
  intentCategory: undefined,
};

const MostRelatedCategoriesContext = React.createContext<MostRelatedCategoriesContextShape>(
  defaultMostRelatedCategoriesContextValue,
);
MostRelatedCategoriesContext.displayName = 'MostRelatedCategoriesContext';

const useMostRelatedCategoriesContext = () => useContext(MostRelatedCategoriesContext);

export { MostRelatedCategoriesContext, MostRelatedCategoriesContextShape, useMostRelatedCategoriesContext };
