import React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import { I18nextProvider } from '@wix/wix-i18n-config';
import { i18n } from './i18n/client-i18n';
import { App } from './App/App';
import * as BIManager from './utils/bi';
import { DEVICE } from './utils/helpers';
import { initWixRecorder } from './utils/initWixRecorder';
import { initCookieAndTagManagers } from './utils/initCookieAndTagManagers';
import { ContextProvider } from './context/ContextProvider';
import { WixDesignSystemProvider } from '@wix/design-system';
import '@wix/design-system/styles.global.css';
import { SaveTemplateButtonContextProvider } from './context/SaveTemplateButtonContext/SaveTemplateButtonContextProvider';
import { MostRelatedCategoriesContextProvider } from './context/MostRelatedCategoriesContext/MostRelatedCategoriesContextProvider';
import { UrlParamsContextProvider } from './context/UrlParamsContext/UrlParamsContextProvider';
import { TemplateTitleContextProvider } from './context/TemplateTitleContext/TemplateTitleContextProvider';

const initialI18n = window.__INITIAL_I18N__;
const baseURL = window.__BASEURL__;
const initialState = window.__INITIAL_STATE__;
const consentPolicy = window.__CONSENT_POLICY__;
const device = window.__DEVICE__;
const biOptions = window.__BI__;

(() => {
  initCookieAndTagManagers({
    baseUrl: baseURL,
    consentPolicy,
    language: initialI18n.locale,
  });

  initWixRecorder(initialState.activeExperiments);

  BIManager.setBIOptions(biOptions);

  const app = (
    <ContextProvider initialState={initialState}>
      <I18nextProvider i18n={i18n(initialI18n)}>
        <WixDesignSystemProvider features={{ newColorsBranding: true }}>
          <MostRelatedCategoriesContextProvider
            initialState={{
              categories: initialState.mostRelatedCategories,
            }}
          >
            <UrlParamsContextProvider
              urlParams={{
                dashboardFirstMetaSiteId: initialState.dashboardFirstMetaSiteId,
                siteCreationFolderId: initialState.siteCreationFolderId,
                petri_ovr: initialState.petri_ovr,
              }}
            >
              <SaveTemplateButtonContextProvider>
                <TemplateTitleContextProvider>
                  <App mobile={device !== DEVICE.DESKTOP} />
                </TemplateTitleContextProvider>
              </SaveTemplateButtonContextProvider>
            </UrlParamsContextProvider>
          </MostRelatedCategoriesContextProvider>
        </WixDesignSystemProvider>
      </I18nextProvider>
    </ContextProvider>
  );

  const container = document.getElementById('root');
  // hack for insider issues need to use directly render.
  // Only dev purposes specs.funnel.TemplateViewerOffReactDomHydrate
  if (initialState.activeExperiments.includes('TemplateViewerOffReactDomHydrate')) {
    createRoot(container!).render(app);
  } else {
    hydrateRoot(container!, app, {
      onRecoverableError: (error) => {
        console.error(error);
        // @ts-expect-error
        window?.Sentry?.captureException(error);
      },
    });
  }
})();
